<template>
  <div class="position-relative">
    <canvas :id="id" :height="height"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      required: false,
    },
    textRight: {
      required: false,
    },
    type: {
      required: false,
    },
    hideLegend: {
      required: false,
      default: false,
      type: Boolean,
    },
    filterValue: {
      require: false,
      type: String,
    },
    xText: {
      require: false,
    },
    isStacked: {
      required: false,
      default: false,
      type: Boolean,
    },
    multiAxis: {
      required: false,
      default: () => [],
    },
    height: {
      required: false,
      default: 300,
    },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new this.$Chart(ctx, {
        type: this.type,
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          plugins: {
            datalabels: {
              display:
                this.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? "auto"
                  : false,
              font: {
                weight: "bold",
                size: 12,
              },
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${
                  that.xText || data.datasets[tooltipItem[0].datasetIndex].label
                } (${data.labels[tooltipItem[0].index]})`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
          },
          legend: {
            position: "bottom",
            labels: { boxWidth: 10, boxHeight: 10 },
          },

          scales: {
            yAxes: [
              {
                id: "A",
                type: "linear",
                position: "left",
                scaleLabel: {
                  display: this.text == "" ? false : true,
                  fontStyle: "bold",
                  labelString: this.text || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  min: 0,
                  suggestedMax: 10,
                  fontSize: 10,
                },
              },
              {
                display: this.textRight,
                id: "B",
                type: "linear",
                position: "right",
                scaleLabel: {
                  display: this.textRight,
                  fontStyle: "bold",
                  labelString: this.textRight || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  autoSkip: true,
                  min: 0,
                  beginAtZero: true,
                  fontSize: 10,
                },
                gridLines: {
                  display: false,
                },
              },
              {
                display: false,
                id: "C",
                ticks: {
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                  autoSkip: true,
                  min: 0,
                  beginAtZero: true,
                  fontSize: 10,
                },
              },
            ],

            xAxes: [
              {
                scaleLabel: {
                  display: this.xText == "" ? false : true,
                  fontStyle: "bold",
                  labelString: this.xText || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: { maxRotation: 20, min: 0, fontSize: 10 },
                stacked: this.isStacked,
              },
            ],
          },
        },
      });
    },
  },
};
</script>

<style lang="scss"></style>
